import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    SwipeableDrawer,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserProfileLink } from 'src/types/user-profile.type';
import LogoutButton from '../common/LogoutButton/LogoutButton';
import { ProfileIcon } from '../common/ProfileIcon/ProfileIcon';
import { SwipeableDrawerFooter, SwipeableDrawerHeader } from '../common/SwipeableDrawer';
import { useRccContext } from 'src/contexts';

const UserProfileSettingsDrawerLink = ({
    link,
    selected,
}: {
    link: UserProfileLink;
    selected: boolean;
}) => {
    const { linkProps = {} } = link;
    return (
        <Link {...linkProps} to={link.url}>
            <ListItemButton
                sx={{
                    padding: '12px 16px',
                }}
                selected={selected}
                key={link.url}
            >
                {link.icon && (
                    <Box
                        display='flex'
                        lineHeight={1}
                        color={(theme: Theme) => theme.palette.action.active}
                        paddingRight='8px'
                    >
                        {link.icon}
                    </Box>
                )}
                <Typography variant='body1' textAlign='center'>
                    {link.label}
                </Typography>
            </ListItemButton>
        </Link>
    );
};

export const UserProfileSettingsDrawer = ({
    menuOpen,
    onMenuOpen,
    onMenuClose,
    links,
}: {
    menuOpen: boolean;
    onMenuOpen: () => void;
    onMenuClose: () => void;
    links: UserProfileLink[];
}) => {
    const { userInfo } = useRccContext();
    const theme = useTheme();
    const location = useLocation();
    const [hasUserName, setHasUserName] = useState<boolean>(false);

    useEffect(() => {
        setHasUserName(Boolean(userInfo && userInfo));
    }, [userInfo]);

    return (
        <SwipeableDrawer
            disableDiscovery={true}
            disableSwipeToOpen={true}
            anchor='bottom'
            open={menuOpen}
            onClose={onMenuClose}
            onOpen={onMenuOpen}
        >
            <SwipeableDrawerHeader>
                {hasUserName && (
                    <Fragment>
                        <Box
                            sx={{
                                padding: '12px 8px',
                            }}
                            display='flex'
                            alignItems='center'
                        >
                            <Box paddingRight='16px'>
                                <ProfileIcon userInfo={userInfo} size='laptop' />
                            </Box>
                            <Typography variant='body1'>{userInfo?.name}</Typography>
                        </Box>
                        <Divider />
                    </Fragment>
                )}
            </SwipeableDrawerHeader>
            <List>
                {links.map((link: UserProfileLink) => (
                    <ListItem
                        disablePadding
                        sx={{
                            a: {
                                textDecoration: 'none',
                                width: '100%',
                                color: 'inherit',
                            },
                        }}
                        onClick={onMenuClose}
                        key={link.url}
                    >
                        <UserProfileSettingsDrawerLink
                            key={link.url}
                            selected={location.pathname === link.url}
                            link={link}
                        />
                    </ListItem>
                ))}
            </List>
            <SwipeableDrawerFooter
                sx={{
                    padding: 0,
                }}
            >
                <Box sx={{ px: '8px' }}>
                    <Divider />
                </Box>
                <LogoutButton
                    size='large'
                    sx={{
                        width: '100%',
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        padding: '12px 16px',
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.body1.fontWeight,
                        fontSize: theme.typography.body1.fontSize,
                    }}
                />
            </SwipeableDrawerFooter>
        </SwipeableDrawer>
    );
};
