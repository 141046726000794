import { AppConstants } from 'src/constants/app';
import { NcrPageResponse, Organization } from 'src/types';
import { SwrStaticKeys } from 'src/types/swr-keys.type';
import useSWR, { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { Global } from '../constants/global';
import { formatOrganizations, getOrgShortName } from '../utils/organization-service.utils';
import { DefaultErrorHandler } from '../utils/services.utils';
import { get } from './common.fetchers';

const baseUrl = () => Global.Services.Provisioning;

const fetchOrgByName = (key: string, orgName: string): Promise<Organization> => {
    return get<Organization>(`${baseUrl()}${key}`, {
        headers: {
            //Overwrite stored header
            [AppConstants.OrgHeader]: orgName,
        },
    })
        .then((org: Organization) => {
            org.organizationName = getOrgShortName(org.organizationName);
            return org;
        })
        .catch(DefaultErrorHandler);
};

const fetchUserEffectiveOrganizations = (
    pageNumber: number,
): Promise<NcrPageResponse<Organization>> => {
    return get<NcrPageResponse<Organization>>(`${baseUrl()}/user-profiles/effective-organizations`, {
        params: {
            pageNumber,
            pageSize: 50,
        },
    }).catch(DefaultErrorHandler);
};

const fetchAllUserEffectiveOrganizations = async () => {
    const orgResults: NcrPageResponse<Organization> = await fetchUserEffectiveOrganizations(0);
    return formatOrganizations(orgResults.pageContent);
};

const useGetOrganizationByName = (orgName: string, config: SWRConfiguration<Organization> = {}) => {
    const {
        data: organizationData,
        isLoading: isLoadingOrg,
        error: errorOrg,
    } = useSWR(
        orgName ? [`/organizations/${orgName}`, orgName] : null,
        ([url, orgName]) => fetchOrgByName(url, orgName),
        {
            /**
             * https://swr.vercel.app/docs/advanced/performance#deduplication
             * Allows deduping of requests with the same key in this time span of miliseconds
             * We set it to 10 seconds since we don't expect changes in the response too often
             */
            dedupingInterval: 10000,
            ...config,
        },
    );

    return {
        organizationData,
        isLoadingOrg,
        errorOrg,
    };
};

const useGetEffectiveOrganizations = (
    isAuthenticated: boolean,
    isInternal: boolean | undefined,
    config: SWRConfiguration<Organization[]> = {},
) => {
    const {
        data: organizationList,
        isLoading: isLoadingEffectiveOrgs,
        error: errorOrgs,
    } = useSWRImmutable(
        isAuthenticated && isInternal === false ? SwrStaticKeys.ALL_ORGS : null,
        fetchAllUserEffectiveOrganizations,
        config,
    );

    return {
        organizationList,
        isLoadingEffectiveOrgs,
        errorOrgs,
    };
};

export { baseUrl, useGetEffectiveOrganizations, useGetOrganizationByName };

