import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider, Menu, MenuItem, Theme, useTheme } from '@mui/material';
import { MutableRefObject, ReactNode } from 'react';
import { ThemeValues } from 'src/constants/theme-values';
import { useSwitcherContext } from 'src/contexts/SwitcherContext';
import { getDefaultConfig } from './SwitcherProvider';

const switcherDefaults = getDefaultConfig();

export const SwitcherMenu = ({
    menuTitle,
    ctaTitle,
    onCtaClick,
    anchorRef,
    menuOpen,
    children,
    onMenuClose,
}: {
    menuTitle: string;
    ctaTitle: string;
    onCtaClick: () => void;
    anchorRef: MutableRefObject<HTMLButtonElement | null>;
    menuOpen: boolean;
    children: ReactNode;
    onMenuClose: () => void;
}) => {
    const { menuAnchorOrigin: defaultAnchorOrigin, menuTransformOrigin: defaultTransformOrigin } =
        switcherDefaults;
    const theme = useTheme<Theme>();
    const { menuAnchorOrigin, menuTransformOrigin } = useSwitcherContext();

    return (
        <Menu
            anchorEl={() => anchorRef.current as HTMLButtonElement}
            anchorOrigin={menuAnchorOrigin || defaultAnchorOrigin}
            transformOrigin={menuTransformOrigin || defaultTransformOrigin}
            keepMounted
            sx={{
                '.MuiMenu-paper': {
                    width: '286px',
                    maxHeight: '342px',
                    borderRadius: ThemeValues.BorderRadius,
                },
            }}
            MenuListProps={{
                sx: {
                    padding: '4px 0px 0px',
                },
            }}
            open={menuOpen}
            onClose={onMenuClose}
        >
            <MenuItem
                aria-hidden={true}
                disabled={true}
                sx={{
                    padding: '12px 16px 8px 16px',
                    fontWeight: 500,
                    fontSize: theme.typography.body2.fontSize,
                    color: theme.palette.text.secondary,
                    '&.Mui-disabled': {
                        opacity: 1,
                    },
                }}
            >
                {menuTitle}
            </MenuItem>
            {children}
            <Divider
                sx={{
                    '&.MuiDivider-root.MuiDivider-fullWidth': {
                        margin: 0,
                    },
                }}
            />
            <MenuItem
                sx={{
                    padding: '16px',
                    fontWeight: 500,
                    fontSize: theme.typography.body2.fontSize,
                    color: theme.palette.primary.dark,
                }}
                onClick={onCtaClick}
            >
                {ctaTitle}
                <ArrowForwardIcon sx={{ fontSize: '20px', marginLeft: '8px' }} />
            </MenuItem>
        </Menu>
    );
};
