import { RccEventEmitter } from 'src/classes/event-emitter';
import { AppEnv, RccEnvironment, ServiceTypeEndpoints } from '../types';

export const Global: AppEnv = {
    Services: null,
    AccessToken: '',
    NepOrganization: '',
    IdmUrl: null
};

type RccIdmKeys = Extract<RccEnvironment, 'dev' | 'stg' | 'prd'>;

export const IDMUrlsByEnv: Record<RccIdmKeys, string> = {
    dev: 'https://id-dev.ncrcloud.com',
    stg: 'https://id-stg.ncrcloud.com',
    prd: 'https://identity.ncr.com',
};

export const ApigeeGateways: Record<RccEnvironment, ServiceTypeEndpoints> = {
    dev: {
        Provisioning: "https://gateway-dev-x.ncrcloud.com/provisioning",
        Applications: "https://gateway-dev-x.ncrcloud.com/applications",
    },
    stg: {
        Provisioning: "https://gateway-staging-x.ncrcloud.com/provisioning",
        Applications: "https://gateway-staging-x.ncrcloud.com/applications",
    },
    prd: {
        Provisioning: "https://api.ncr.com/provisioning",
        Applications: "https://api.ncr.com/applications",
    },
    napac: {
        Provisioning: "https://api-napac.ncr.com/provisioning",
        Applications: "https://api-napac.ncr.com/applications",
    },
    apac: {
        Provisioning: "https://api-apac.ncr.com/provisioning",
        Applications: "https://api-apac.ncr.com/applications",
    },
    emea: {
        Provisioning: "https://api-emea.ncr.com/provisioning",
        Applications: "https://api-emea.ncr.com/applications",
    },
}

export const GlobalEmitter = new RccEventEmitter();
