import { Link, MenuItem } from '@mui/material';
import { MutableRefObject } from 'react';
import { SwitcherMenu } from 'src/components/common/Switcher/SwitcherMenu';
import { AppConstants } from 'src/constants/app';
import { ThemeValues } from 'src/constants/theme-values';
import { useApplicationsContext, useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApplicationModel } from 'src/types/application.type';
import { getContextRedirectUrl } from 'src/utils/application-service.utils';

export const ApplicationSwitcherMenu = ({
    anchorRef,
    menuOpen,
    onMenuClose,
}: {
    anchorRef: MutableRefObject<HTMLButtonElement | null>;
    menuOpen: boolean;
    onMenuClose: () => void;
}) => {
    const { t } = useTranslationNs();
    const { application, setIsAppDialogOpen, userApplications } = useApplicationsContext();
    const { organization } = useOrganizationContext();

    const handleAppClick = () => {
        onMenuClose();
    };

    const handleViewAll = () => {
        onMenuClose();
        setIsAppDialogOpen(true);
    };

    const allApps = userApplications
        .slice(0, AppConstants.AppMenuMax)
        .map((appGrant: ApplicationModel) => (
            <MenuItem
                sx={{
                    padding: ThemeValues.MenuItemPadding,
                }}
                selected={appGrant.name === application?.name}
                key={appGrant.name}
                component={Link}
                href={getContextRedirectUrl(organization.organizationName, appGrant.redirectUrl)}
                target='_blank'
                onClick={handleAppClick}
            >
                {appGrant.displayName}
            </MenuItem>
        ));

    return (
        <SwitcherMenu
            anchorRef={anchorRef}
            ctaTitle={t('APPLICATION_CONTEXT.MENU.MANAGE_ALL_APPS')}
            menuTitle={t('APPLICATION_CONTEXT.MENU.TITLE')}
            menuOpen={menuOpen}
            onCtaClick={handleViewAll}
            onMenuClose={onMenuClose}
        >
            {allApps}
        </SwitcherMenu>
    );
};
