import LanRoundedIcon from '@mui/icons-material/LanRounded';
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { Switcher } from 'src/components/common/Switcher/Switcher';
import {
    SwitcherProvider,
    getDefaultConfig,
} from 'src/components/common/Switcher/SwitcherProvider';
import { SwitcherOptions, useOrganizationContext } from 'src/contexts';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { OrganizationSwitcherDialog } from '../OrganizationSwitcherDialog/OrganizationSwitcherDialog';
import { OrganizationSwitcherDrawer } from './OrganizationSwitcherDrawer';
import { OrganizationSwitcherMenu } from './OrganizationSwitcherMenu';

export const NonFederatedOrganizationSwitcher = ({
    config = getDefaultConfig(),
}: {
    config?: SwitcherOptions;
}) => {
    const { t } = useTranslationNs();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { isLoading, organization, userOrganizations } = useOrganizationContext();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const isMobile = useMobileView();

    const handleOpenOrgMenu = () => {
        setMenuOpen(true);
    };

    const handleCloseOrgMenu = () => {
        setMenuOpen(false);
    };

    let switcherLabel: ReactNode;
    if (isLoading) switcherLabel = t('COMMON.LOADING');
    else if (!organization) {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.EMPTY');
    } else switcherLabel = organization?.displayName || organization?.organizationName;

    return (
        <SwitcherProvider config={config || {}}>
            <Switcher
                tooltipTitle={t('ORG_CONTEXT.ACCESSIBILITY.SWITCHER')}
                disabled={userOrganizations.length <= 1}
                enableSwitcher={userOrganizations.length !== 1}
                isLoading={isLoading}
                onClick={handleOpenOrgMenu}
                startIcon={<LanRoundedIcon fontSize='small' />}
                ref={buttonRef}
            >
                {switcherLabel}
            </Switcher>
            {isMobile ? (
                <OrganizationSwitcherDrawer
                    menuOpen={menuOpen}
                    onMenuOpen={handleOpenOrgMenu}
                    onMenuClose={handleCloseOrgMenu}
                />
            ) : (
                <OrganizationSwitcherMenu
                    anchorRef={buttonRef}
                    menuOpen={menuOpen}
                    onMenuClose={handleCloseOrgMenu}
                />
            )}
            <OrganizationSwitcherDialog />
        </SwitcherProvider>
    );
};
