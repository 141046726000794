import { Skeleton } from '@mui/material';
import { ThemeValues } from 'src/constants/theme-values';
import { useRccContext } from 'src/contexts';
import { isInternalUser } from 'src/utils/user.utils';
import { FederatedOrganizationSwitcher } from './FederatedOrganizationSwitcher';
import { NonFederatedOrganizationSwitcher } from './NonFederatedOrganizationSwitcher';

export const OrganizationSwitcher = () => {
    const { userInfo } = useRccContext();
    const isInternal: boolean | undefined = isInternalUser(userInfo);

    if (isInternal === undefined) {
        return (
            <Skeleton
                variant='rectangular'
                height='40px'
                width='140px'
                sx={{ borderRadius: ThemeValues.BorderRadius }}
            />
        );
    }

    if (isInternal === true) {
        return <FederatedOrganizationSwitcher />;
    }

    return <NonFederatedOrganizationSwitcher />;
};
