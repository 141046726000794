import DomainIcon from '@mui/icons-material/Domain';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanIcon from '@mui/icons-material/Lan';
import { Box, IconButton, List, ListItemIcon, Typography } from '@mui/material';
import { Fragment } from 'react';
import { DialogListItemActionButton } from 'src/components/common/Dialog/DialogListItemActionButton';
import { useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { useOrganizationDialogContext } from '../OrganizationProvider/OrganizationDialogProvider';
import { OrganizationHierarchyCollapse } from './OrganizationHierarchyCollapse';

export const OrganizationAllOrgsList = () => {
    const { t } = useTranslationNs();
    const { userOrganizations, organization } = useOrganizationContext();
    const { handleOrganizationClick, searchValue, expandedOrg, handleExpandOrgClick } =
        useOrganizationDialogContext();

    const filteredOrgs = userOrganizations.filter(
        (org) =>
            org.organizationName.includes(searchValue) ||
            org.children.some((child) => child.includes(searchValue)),
    );

    if (filteredOrgs.length === 0) {
        return (
            <Box sx={{ px: '24px' }}>
                <Typography variant='body1'>{t('ORG_CONTEXT.DIALOG.NO_RESULTS')}</Typography>
            </Box>
        );
    }

    return (
        <List>
            {filteredOrgs.map((org) => {
                const orgName = org.organizationName;
                const isExpanded = expandedOrg.has(org.id);
                const expandTitle = isExpanded ? 'COLLAPSE_ORG' : 'EXPAND_ORG';

                return (
                    <Fragment key={`${orgName}`}>
                        <DialogListItemActionButton
                            selected={orgName === organization?.organizationName}
                            onListItemButtonClick={() => handleOrganizationClick(orgName)}
                            secondaryAction={
                                Boolean(org.children.length) && (
                                    <IconButton
                                        title={t(`ORG_CONTEXT.ACCESSIBILITY.${expandTitle}`)}
                                        size='small'
                                        onClick={() => handleExpandOrgClick(org.id)}
                                    >
                                        {isExpanded ? (
                                            <ExpandLessIcon fontSize='inherit' />
                                        ) : (
                                            <ExpandMoreIcon fontSize='inherit' />
                                        )}
                                    </IconButton>
                                )
                            }
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: '0',
                                    marginRight: '16px',
                                }}
                            >
                                {Boolean(org.children.length) ? <LanIcon /> : <DomainIcon />}
                            </ListItemIcon>
                            <Typography variant='body1'>{orgName}</Typography>
                        </DialogListItemActionButton>

                        {Boolean(org.children) && (
                            <OrganizationHierarchyCollapse organization={org} />
                        )}
                    </Fragment>
                );
            })}
        </List>
    );
};
