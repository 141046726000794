import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Close from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button,
    CircularProgress,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Theme,
    Typography,
    debounce,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { ChangeEvent, useEffect, useMemo, useState, useRef, MutableRefObject } from 'react';
import { useOrganizationContext } from 'src/contexts';
import { useGetOrganizationByName } from 'src/fetchers/organization.fetchers';
import { useTranslationNs } from 'src/hooks/useTranslationNs';

const StatusAdornment = ({
    searchTerm,
    isLoading,
    isValid,
}: {
    searchTerm: string;
    isLoading: boolean;
    isValid: boolean;
}) => {
    if (!searchTerm) {
        return <SearchIcon color='inherit' />;
    }

    if (isLoading) {
        return <CircularProgress thickness={5} color={'inherit'} size={'20px'} />;
    }

    if (isValid) {
        return <CheckCircleIcon color='success' />;
    } else {
        return <CancelIcon color='error' />;
    }
};

export const OrganizationFederatedDialog = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const inputRef: MutableRefObject<HTMLInputElement> = useRef();
    const { t } = useTranslationNs();
    const { updateOrganization } = useOrganizationContext();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [isCtaDisabled, setIsCtaDisabled] = useState<boolean>();
    const { organizationData, isLoadingOrg, errorOrg } = useGetOrganizationByName(searchTerm, {
        keepPreviousData: false,
    });
    const validOrg = !!organizationData;
    const debouncedChangeHandler = useMemo(
        () =>
            debounce(async (event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                setSearchTerm(value);
            }, 750),
        [setSearchTerm],
    );

    const setActiveOrganization = () => {
        if (!isCtaDisabled && !isLoadingOrg) {
            updateOrganization(organizationData.organizationName);
            onClose();
        }
    };

    useEffect(
        function resetOnClose() {
            if (!isOpen) {
                setSearchTerm('');
            }
        },
        [isOpen, setSearchTerm],
    );

    useEffect(() => {
        if (!errorOrg) return;
        inputRef.current?.focus();
    }, [errorOrg]);

    useEffect(() => {
        setIsCtaDisabled(!organizationData);
    }, [organizationData]);

    return (
        <Dialog
            sx={{
                '& .MuiPaper-root': {
                    width: '450px',
                    maxWidth: 'calc(100% - 32px)',
                },
            }}
            open={isOpen}
            onClose={onClose}
        >
            <Stack
                justifyContent='space-between'
                alignItems='center'
                direction='row'
                padding='16px 10px 16px 24px'
            >
                <DialogTitle sx={{ padding: 0, fontWeight: 700 }} variant='h6'>
                    {t('ORG_CONTEXT.MENU.TITLE')}
                </DialogTitle>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Stack>
            <DialogContent>
                <Stack rowGap={'16px'}>
                    <TextField
                        inputRef={inputRef}
                        disabled={isLoadingOrg}
                        fullWidth={true}
                        autoComplete='off'
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setIsCtaDisabled(true);
                            debouncedChangeHandler(event);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    sx={{
                                        color: (theme: Theme) => theme.palette.text.disabled,
                                        height: '30px',
                                    }}
                                    position='end'
                                >
                                    <Divider
                                        sx={{
                                            margin: '0px 12px',
                                        }}
                                        variant='fullWidth'
                                        orientation='vertical'
                                    />
                                    <StatusAdornment
                                        isValid={validOrg}
                                        isLoading={isLoadingOrg}
                                        searchTerm={searchTerm}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {errorOrg && (
                        <Typography fontSize={'14px'} color='error'>
                            {t('ORG_CONTEXT.DIALOG.NO_MATCH')}
                        </Typography>
                    )}
                    <Button
                        disabled={isCtaDisabled}
                        onClick={setActiveOrganization}
                        sx={{ textTransform: 'unset' }}
                        fullWidth={true}
                        variant='contained'
                    >
                        {t('ORG_CONTEXT.DIALOG.FEDERATED_CTA')}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
