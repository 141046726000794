import { LoginCallback } from "@okta/okta-react";
import { Route, createRoutesFromElements } from "react-router-dom";
import AppLayout from "../../AppLayout";
import { HomePage } from "../../pages/HomePage";
import Loading from "../loading/Loading";
import { NavigateWithParams } from "../navigation/navigate/NavigateWithParams";
import { AuthenticationGuard } from "../navigation/router-guards/AuthenticationGuard";

export const createAppRoutes = () =>
  createRoutesFromElements(
    <Route element={<AppLayout />}>
      <Route element={<AuthenticationGuard />}>
        <Route path="app/home" element={<HomePage />} />
        <Route path="*" element={<NavigateWithParams to="app/home" />} />
      </Route>
      <Route
        path="login/callback"
        element={<LoginCallback loadingElement={<Loading />} />}
      />
      <Route path="*" element={<div>Not Found</div>} />
    </Route>
  );
