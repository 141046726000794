import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider as MuiTheme } from "@mui/material/styles";
import { ReactNode, useMemo } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { getDesignTokens } from "../../theme/theme-util";

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(() => {
    const mode = prefersDarkMode ? "dark" : "light";
    return createTheme(getDesignTokens(mode));
  }, [prefersDarkMode]);

  return (
    <MuiTheme theme={theme}>
      <CssBaseline />
      {children}
    </MuiTheme>
  );
};
