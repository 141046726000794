import { UnfoldMore } from '@mui/icons-material';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import { Box, Tooltip, useTheme } from '@mui/material';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { ThemeValues } from 'src/constants/theme-values';

type SwitcherProps = {
    children: ReactNode;
    tooltipTitle: string;
    disabled?: boolean;
    enableSwitcher: boolean;
    isLoading: boolean;
    onClick: () => void;
    startIcon?: ReactNode;
};

export const Switcher = forwardRef<HTMLButtonElement, SwitcherProps>(
    (
        {
            children,
            disabled,
            enableSwitcher,
            tooltipTitle,
            isLoading,
            onClick,
            startIcon,
        }: SwitcherProps,
        ref,
    ) => {
        const theme = useTheme();
        const disableSwitcher = disabled == undefined ? isLoading : disabled;
        const startIconProps: Pick<LoadingButtonProps, 'startIcon' | 'loadingPosition'> = {
            startIcon: startIcon,
            loadingPosition: 'start',
        };

        return (
            <Tooltip title={disabled ? '' : tooltipTitle}>
                <span>
                    <LoadingButton
                        ref={ref as ForwardedRef<HTMLButtonElement>}
                        loading={isLoading && !!startIcon}
                        onClick={onClick}
                        variant='text'
                        endIcon={
                            isLoading || enableSwitcher ? <UnfoldMore fontSize='small' /> : null
                        }
                        sx={{
                            columnGap: 1,
                            borderRadius: ThemeValues.BorderRadius,
                            textTransform: 'none',
                            color: theme.palette.text.primary,
                            fontWeight: 400,
                            padding: '6px',
                            '.MuiButton-endIcon, .MuiButton-startIcon': {
                                marginLeft: 0,
                                marginRight: 0,
                                padding: '2px',
                            },
                            '.MuiButton-endIcon svg, .MuiButton-startIcon svg': {
                                marginRight: '-0.5px',
                            },
                            '.MuiButton-startIcon': {
                                color: theme.palette.action.active,
                            },
                            '&.Mui-disabled': {
                                '& > #switcher-label': {
                                    color: theme.palette.text.secondary,
                                    fontWeight: 500,
                                },
                            },
                            maxWidth: '224px',
                        }}
                        disabled={disableSwitcher || isLoading}
                        {...(!!startIcon ? startIconProps : {})}
                    >
                        <Box
                            id='switcher-label'
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                overflow: 'hidden',
                                ...theme.typography.subtitle1,
                                fontWeight: 600,
                            }}
                            aria-live='assertive'
                            aria-atomic='true'
                        >
                            {children}
                        </Box>
                    </LoadingButton>
                </span>
            </Tooltip>
        );
    },
);
