import { Person } from '@mui/icons-material';
import { Avatar, SxProps, useTheme } from '@mui/material';
import { UserClaims } from '@okta/okta-auth-js';
import { Fragment, useEffect, useState } from 'react';

export type ProfileIconSize = 'tablet' | 'laptop';

type ProfileIconSizeConfig = {
    size: string;
    fontSize: string;
};

type IProfileIconSizes = Record<ProfileIconSize, ProfileIconSizeConfig>;

const ProfileIconSizes: IProfileIconSizes = {
    tablet: {
        size: '32px',
        fontSize: '12px',
    },
    laptop: {
        size: '40px',
        fontSize: '16px',
    },
};

export const ProfileIcon = ({
    size = 'tablet',
    userInfo,
}: {
    size?: ProfileIconSize;
    userInfo?: UserClaims;
}) => {
    const [initials, setInitials] = useState<string>('');
    const sizeConfig: ProfileIconSizeConfig = ProfileIconSizes[size];
    const theme = useTheme();

    useEffect(() => {
        if (userInfo) {
            const first = (userInfo.given_name && userInfo.given_name[0]) || '';
            const last = (userInfo.family_name && userInfo.family_name[0]) || '';
            setInitials(`${first.toUpperCase()}${last.toUpperCase()}`);
        } else {
            setInitials('');
        }
    }, [userInfo]);

    const sxProps: SxProps = {
        height: sizeConfig.size,
        width: sizeConfig.size,
        fontSize: sizeConfig.fontSize,
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.common.white,
        lineHeight: 1,
    };

    return (
        <Fragment>
            {Boolean(initials) ? (
                <Avatar sx={sxProps}>{initials}</Avatar>
            ) : (
                <Avatar sx={sxProps}>
                    <Person fontSize='medium' />
                </Avatar>
            )}
        </Fragment>
    );
};
