import { AppBar, Stack, Theme } from "@mui/material";
import {
  OrganizationSwitcher,
  UserProfileSettings,
} from "@ncr-voyix-commerce/react-common-components";
import { useOktaAuth } from "@okta/okta-react";

export const DemoAppBar = () => {
  const { authState } = useOktaAuth();

  if (!authState?.isAuthenticated) {
    return <></>;
  }

  return (
    <AppBar
      position="static"
      sx={(theme: Theme) => ({
        px: "var(--app-padding-side)",
        py: "8px",
        top: 0,
        boxShadow: "none",
        left: 0,
        justifyContent: "flex-end",
        color: theme.palette.text.primary,
        rowGap: 1,
        columnGap: 2,
        bgcolor: theme.palette.background.default,
        backgroundImage: "none",
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      })}
    >
      <Stack direction="row" alignItems="center" columnGap="16px">
        <OrganizationSwitcher />
        <UserProfileSettings />
      </Stack>
    </AppBar>
  );
};
