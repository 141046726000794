import { Person } from '@mui/icons-material';
import { Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Fragment, MutableRefObject, useRef, useState } from 'react';
import { Global } from 'src/constants/global';
import { useRccContext } from 'src/contexts';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { UserProfileLink } from 'src/types/user-profile.type';
import { ProfileIcon } from '../common/ProfileIcon/ProfileIcon';
import { UserProfileSettingsDrawer } from './UserProfileSettingsDrawer';
import { UserProfileSettingsMenu } from './UserProfileSettingsMenu';

export const UserProfileSettings = ({ inputLinks = [] }: { inputLinks?: UserProfileLink[] }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { t } = useTranslationNs();
    const isMobile = useMobileView();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const { userInfo } = useRccContext();

    const navigationSettingLinks: UserProfileLink[] = [
        {
            icon: <Person color='inherit' fontSize='medium' />,
            label: t('USER_PROFILE.ACCOUNT'),
            url: `${Global.IdmUrl}/user-profile`,
            linkProps: {
                target: '_blank',
            },
        },
        ...inputLinks,
    ];

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };
    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    if (!userInfo) {
        return (
            <Skeleton
                variant='circular'
                sx={{
                    margin: '8px',
                    height: '32px',
                    width: '32px',
                }}
            />
        );
    }

    return (
        <Fragment>
            <Button
                onClick={handleMenuOpen}
                ref={buttonRef}
                type='button'
                sx={{ borderRadius: '50%', padding: '8px', minWidth: 'unset' }}
            >
                <ProfileIcon userInfo={userInfo} />
            </Button>
            {isMobile ? (
                <UserProfileSettingsDrawer
                    menuOpen={menuOpen}
                    links={navigationSettingLinks}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                />
            ) : (
                <UserProfileSettingsMenu
                    links={navigationSettingLinks}
                    anchorRef={buttonRef}
                    menuOpen={menuOpen}
                    onMenuClose={handleMenuClose}
                />
            )}
        </Fragment>
    );
};
