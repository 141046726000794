import { QueryParamKeys } from '../types';

export const getContextRedirectUrl = (
    organizationName: string,
    applicationRedirect: string,
): string => {
    let redirect = new URL(applicationRedirect);
    redirect.searchParams.set(QueryParamKeys.organization, organizationName);

    return redirect.href;
};
