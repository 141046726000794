import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Global } from 'src/constants/global';
import { IOrganizationContext, useRccContext } from 'src/contexts';
import { OrganizationContext } from 'src/contexts/OrganizationContext';
import {
    useGetEffectiveOrganizations,
    useGetOrganizationByName,
} from 'src/fetchers/organization.fetchers';
import { Organization } from 'src/types/organization.type';
import { useSearchParams } from 'react-router-dom';
import { QueryParamKeys } from 'src/types';
import { isInternalUser } from 'src/utils/user.utils';

export const OrganizationContextProvider = ({ children }: { children: ReactNode }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams({});
    const [error, setError] = useState();
    const { isAuthenticated, userInfo } = useRccContext();
    const [organizationName, setOrganizationName] = useState<string>();
    const [organization, setOrganization] = useState<Organization>();
    const [userOrganizations, setUserOrganizations] = useState<Organization[]>([]);
    const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);
    const isInternal: boolean | undefined = isInternalUser(userInfo);
    const { organizationList, isLoadingEffectiveOrgs, errorOrgs } = useGetEffectiveOrganizations(
        isAuthenticated,
        isInternal,
    );

    const internalUpdateOrganization = useCallback(
        (org: Organization | null) => {
            Global.NepOrganization = org?.organizationName;
            setOrganization(org);
        },
        [setOrganization],
    );

    const { organizationData, isLoadingOrg, errorOrg } = useGetOrganizationByName(
        organizationName,
        {
            onError: () => {
                if (searchParams.get(QueryParamKeys.organization)) {
                    searchParams.delete(QueryParamKeys.organization);
                    setSearchParams(searchParams);
                }

                if (isInternal) {
                    setIsOrgDialogOpen(true);
                    return;
                }

                const erroredOrg = userOrganizations.find(
                    (org) => organizationName === org.organizationName,
                );

                internalUpdateOrganization(erroredOrg);
            },
            keepPreviousData: false,
        },
    );

    useEffect(
        function setActiveOrganization() {
            if (!organizationData) return;
            internalUpdateOrganization(organizationData);
        },
        [organizationData, internalUpdateOrganization],
    );

    useEffect(
        function setActiveParams() {
            if (
                organization &&
                searchParams.get(QueryParamKeys.organization) !== organization.organizationName
            ) {
                searchParams.set(QueryParamKeys.organization, organization.organizationName);
                setSearchParams(searchParams);
            }
        },
        [organization],
    );

    useEffect(
        function resetOrganizationData() {
            if (!isAuthenticated) {
                setOrganizationName(null);
                setUserOrganizations([]);
                Global.NepOrganization = '';
            }
        },
        [isAuthenticated, setUserOrganizations, setOrganizationName],
    );

    useEffect(
        function setUserEffectiveOrganizations() {
            setUserOrganizations(organizationList ?? []);
        },
        [organizationList, setUserOrganizations],
    );

    useEffect(
        function setLoadingState() {
            setIsLoading(isLoadingOrg || isLoadingEffectiveOrgs || !organization);
        },
        [isLoadingOrg, isLoadingEffectiveOrgs, organization, setIsLoading],
    );

    useEffect(
        function setErrorState() {
            setError(errorOrg || errorOrgs);
        },
        [errorOrg, errorOrgs, setError],
    );

    const updateOrganizationName = useCallback(
        (org: string) => {
            setOrganizationName((previousOrgName: string) =>
                org == previousOrgName ? previousOrgName : org,
            );
        },
        [setOrganizationName],
    );

    useEffect(
        function chooseDefaultOrg() {
            if (isInternal == undefined) return;

            let paramOrgId = searchParams.get(QueryParamKeys.organization);

            if (!isInternal && userOrganizations.length > 0) {
                if (paramOrgId) {
                    if (userOrganizations.some((org) => org.organizationName === paramOrgId)) {
                        updateOrganizationName(paramOrgId);
                        return;
                    } else {
                        searchParams.delete(QueryParamKeys.organization);
                        setSearchParams(searchParams);
                    }
                }

                updateOrganizationName(userOrganizations[0].organizationName);
            } else if (!!isInternal && paramOrgId) {
                updateOrganizationName(paramOrgId);
            } else if (!!isInternal && !paramOrgId) {
                setIsOrgDialogOpen(true);
            }
        },
        [isInternal, userOrganizations, updateOrganizationName, setIsOrgDialogOpen],
    );

    const contextValue: IOrganizationContext = useMemo(
        () => ({
            organization,
            isOrgDialogOpen,
            setIsOrgDialogOpen,
            userOrganizations,
            updateOrganization: updateOrganizationName,
            isLoading,
            error,
        }),
        [
            organization,
            isOrgDialogOpen,
            setIsOrgDialogOpen,
            userOrganizations,
            updateOrganizationName,
            isLoading,
            error,
        ],
    );

    return (
        <OrganizationContext.Provider value={contextValue}>{children}</OrganizationContext.Provider>
    );
};
