import { MenuItem, Typography } from '@mui/material';
import { MutableRefObject } from 'react';
import { SwitcherMenu } from 'src/components/common/Switcher/SwitcherMenu';
import { AppConstants } from 'src/constants/app';
import { ThemeValues } from 'src/constants/theme-values';
import { useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Organization } from 'src/types';

export const OrganizationSwitcherMenu = ({
    anchorRef,
    menuOpen,
    onMenuClose,
}: {
    anchorRef: MutableRefObject<HTMLButtonElement | null>;
    menuOpen: boolean;
    onMenuClose: () => void;
}) => {
    const { t } = useTranslationNs();
    const { userOrganizations, updateOrganization, setIsOrgDialogOpen, organization } =
        useOrganizationContext();

    const handleOrgClick = (orgName: string) => {
        onMenuClose();
        updateOrganization(orgName);
    };

    const handleViewAll = () => {
        onMenuClose();
        setIsOrgDialogOpen(true);
    };

    const effectiveOrgs = userOrganizations
        .slice(0, AppConstants.OrgMenuMax)
        .map((org: Organization) => (
            <MenuItem
                sx={{
                    padding: ThemeValues.MenuItemPadding,
                }}
                selected={org.organizationName === organization?.organizationName}
                key={org.id}
                onClick={() => handleOrgClick(org.organizationName)}
            >
                <Typography variant='subtitle2'>{org.displayName}</Typography>
            </MenuItem>
        ));

    return (
        <SwitcherMenu
            anchorRef={anchorRef}
            ctaTitle={t('ORG_CONTEXT.MENU.MANAGE_ALL_ORGS')}
            menuTitle={t('ORG_CONTEXT.MENU.TITLE')}
            menuOpen={menuOpen}
            onCtaClick={handleViewAll}
            onMenuClose={onMenuClose}
        >
            {effectiveOrgs}
        </SwitcherMenu>
    );
};
