import LanRoundedIcon from '@mui/icons-material/LanRounded';
import { Fragment, ReactNode } from 'react';
import { Switcher } from 'src/components/common/Switcher/Switcher';
import { useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { OrganizationFederatedDialog } from '../OrganizationFederatedSwitcher/OrganizationFederatedDialog';

export const FederatedOrganizationSwitcher = () => {
    const { t } = useTranslationNs();
    const { setIsOrgDialogOpen, organization, isOrgDialogOpen } = useOrganizationContext();

    let switcherLabel: ReactNode;
    if (!organization) {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.EMPTY');
    } else switcherLabel = organization?.displayName || organization?.organizationName;

    const handleOpenFederatedDialog = () => {
        setIsOrgDialogOpen(true);
    };

    const handleCloseFederatedDialog = () => {
        setIsOrgDialogOpen(false);
    };

    return (
        <Fragment>
            <Switcher
                tooltipTitle={t('ORG_CONTEXT.ACCESSIBILITY.SWITCHER')}
                enableSwitcher={true}
                isLoading={false}
                onClick={handleOpenFederatedDialog}
                startIcon={<LanRoundedIcon fontSize='small' />}
            >
                {switcherLabel}
            </Switcher>

            <OrganizationFederatedDialog
                isOpen={isOrgDialogOpen}
                onClose={handleCloseFederatedDialog}
            />
        </Fragment>
    );
};
