import { useOktaAuth } from '@okta/okta-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { RccEvents } from 'src/classes/event-emitter';
import { OrganizationContextProvider } from 'src/components/organization/OrganizationProvider/OrganizationProvider';
import { ApigeeGateways, Global, GlobalEmitter, IDMUrlsByEnv } from 'src/constants/global';
import { IRccContext, RccContext } from 'src/contexts/RccContext';
import { useGetOktaUser } from 'src/fetchers/okta.fetchers';
import i18nShared from 'src/i18n/config';
import { RccContextConfig, RccEventKeys } from 'src/types';
import { localStorageProvider } from 'src/utils/local-storage.utils';
import { SWRConfig } from 'swr';
import { RccCacheOperator } from './RccCacheOperator';
import { RccLanguageOperator } from './RccLanguageOperator';

const listener = new RccEvents(GlobalEmitter);

export const RccContextProvider = ({
    config,
    children,
}: {
    config: RccContextConfig;
    children: ReactNode;
}) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const userInfo = useGetOktaUser();
    Global.Services = ApigeeGateways[config.environment];
    Global.IdmUrl = IDMUrlsByEnv[config.environment];

    useEffect(
        function authenticate() {
            const accessToken = authState?.accessToken?.accessToken;
            const isAuthenticated = authState?.isAuthenticated && !!accessToken;
            Global.AccessToken = isAuthenticated ? accessToken : '';
            setIsAuthenticated(isAuthenticated);
        },
        [authState, setIsAuthenticated],
    );

    useEffect(
        function autoLogout() {
            const enabled = config?.enableAutoLogout ?? true;
            const onExpire = async () => {
                await oktaAuth.signOut();
                GlobalEmitter.emit(RccEventKeys.SIGNOUT);
            };

            if (!!enabled) {
                oktaAuth.tokenManager.on('expired', onExpire);
            }

            return () => {
                if (!!enabled) {
                    oktaAuth.tokenManager.off('expired', onExpire);
                }
            };
        },
        [oktaAuth.tokenManager, config.enableAutoLogout],
    );

    const contextValue: IRccContext = useMemo(
        () => ({
            isAuthenticated,
            events: listener,
            userInfo,
        }),
        [isAuthenticated, userInfo],
    );

    return (
        <RccContext.Provider value={contextValue}>
            <I18nextProvider i18n={i18nShared}>
                <SWRConfig
                    value={{
                        provider: localStorageProvider,
                        keepPreviousData: true,
                        revalidateOnFocus: false,
                        errorRetryCount: 0,
                    }}
                >
                    <RccLanguageOperator language={config.language} />
                    <RccCacheOperator isAuthenticated={isAuthenticated} />
                    <OrganizationContextProvider>{children}</OrganizationContextProvider>
                </SWRConfig>
            </I18nextProvider>
        </RccContext.Provider>
    );
};
