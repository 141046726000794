import { Global } from 'src/constants/global';
import { ApplicationModel, ApplicationUserGrant } from 'src/types/application.type';
import { DefaultErrorHandler } from 'src/utils/services.utils';
import { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { Organization } from '..';
import { get } from './common.fetchers';

const baseUrl = () => Global.Services.Applications;

const fetchApplicationsForUser = (key: string): Promise<ApplicationModel[]> => {
    return get<ApplicationUserGrant[]>(`${baseUrl()}${key}`)
        .then((response: ApplicationUserGrant[]) => {
            return response.map((aug: ApplicationUserGrant) => aug.application);
        })
        .catch(DefaultErrorHandler);
};

const useGetApplicationsForUser = (
    organization: Organization,
    config: SWRConfiguration<ApplicationModel[]> = {},
) => {
    const {
        data: userApplications,
        isLoading,
        error,
    } = useSWRImmutable(
        organization?.organizationName
            ? ['/application-grants/user-grants/current', organization?.organizationName]
            : null,
        ([key]) => fetchApplicationsForUser(key),
        config,
    );

    return {
        userApplications: userApplications || [],
        isLoading,
        error,
    };
};

export { useGetApplicationsForUser };
