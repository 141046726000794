import { Box, Theme } from "@mui/material";
import {
  RccContextConfig,
  RccContextProvider,
} from "@ncr-voyix-commerce/react-common-components";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { Outlet, useNavigate } from "react-router-dom";
import "./AppLayout.css";
import { DemoAppBar } from "./components/navigation/DemoAppBar";
import { ThemeProvider } from "./components/providers/ThemeProvider";
import { oktaAuthOptions } from "./constants/okta-auth-config";

const rccConfig: RccContextConfig = {
  language: "en",
  environment: window._env_.REACT_APP_ENVIRONMENT,
};

const oktaAuth = new OktaAuth(oktaAuthOptions);

function AppLayout() {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "", window.location.origin), {
      replace: true,
    });
  };

  return (
    <div className="App">
      <ThemeProvider>
        <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
          <RccContextProvider config={rccConfig}>
            <DemoAppBar />
            <Box
              height="100%"
              px="var(--app-padding-side)"
              paddingTop="var(--app-padding-header)"
              paddingBottom="var(--app-padding-footer)"
              sx={(theme: Theme) => ({
                backgroundColor: theme.palette.background.default,
              })}
            >
              <Outlet />
            </Box>
          </RccContextProvider>
        </Security>
      </ThemeProvider>
    </div>
  );
}

export default AppLayout;
