import { UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { SwrStaticKeys } from 'src/types/swr-keys.type';
import useSWR from 'swr';

export const useGetOktaUser = (): UserClaims => {
    const { authState, oktaAuth } = useOktaAuth();

    const { data: userInfo } = useSWR(
        authState?.isAuthenticated ? SwrStaticKeys.OKTA_GET_USER : null,
        async () => {
            return await oktaAuth.getUser();
        },
        {
            revalidateOnFocus: false
        }
    );

    return userInfo;
};
